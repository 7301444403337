// partials/_header.scss


div.content-wrapper.headerbar {
	font-family: $titlefont;
	* {
		-webkit-font-smoothing: antialiased;
		color: $header-grey;
	}
	// p.site-name {
	// 	font-size: 1.2em;
	// }
	z-index: 99998;
	height: 80px;
	//margin-bottom: 24px;
	@include for-tablet-landscape-up() {
		height: 80px;
		margin-bottom: 48px;
	}
	&.index {
		background: white;
	}
	display: flex;
	align-items: center;
	justify-content: space-between;
	header.header-global {
		flex: 0 0 50%;
		z-index: 99999;
	}
	div.navigation {
		z-index: 99998;
		flex: 0 0 50%;
		text-align: right;
	}
	&.sticky {
		position: fixed;
		background: white;
		left: 50%;
    transform: translate(-50%, 0);
	}
}