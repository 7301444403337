// partials/_artwork.scss

// Artwork Index

div.artwork-overview {
	&--list {
		display: none;
		@include for-desktop-up() {
			display: block;
		}
		a {
			display: block;
			&:hover {
				text-decoration: underline;
			}
		}
	}
	&--grid {
		transition: all .2s ease-out;
		grid-column: span 2;
		@include for-tablet-landscape-up() {
			grid-column: span 8;
		}
		img.toggle-lightbox {
			cursor: pointer;
		}
		&.greyed-out {
			img {
				transition: all .2s ease-out;
				webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
    			filter: grayscale(100%);
    			opacity: .5;
			}
			a.targeted img {
				webkit-filter: grayscale(0%); /* Safari 6.0 - 9.0 */
    			filter: grayscale(0%);
    			opacity: 1;
			}
		}
		.artwork-grid__item {
			position: relative;
			grid-column: span 2;
			display: flex;
			align-items: center;
			justify-content: center;
			width: 327px;
			height: 327px;
			img {
				max-width: 90%;
				max-height: 90%;
				&.square {
					max-width: 80%;
					max-height: 80%;
				}
			}
			div.artwork-title {
				transition: all .2s ease-out;
				opacity: 0;
				font-family: $titlefont;
				position: absolute;
				background: rgba(255,255,255,.8);
				z-index: 1;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
				&.visible {
					opacity: 1;
				}
			}
		}
	}
	@include for-tablet-portrait-up() {
		.artwork-grid__item {
			grid-column: span 1;
			width: 213px;
			height: 213px;
			img {
				max-width: 90%;
				max-height: 90%;
				&.square {
					max-width: 80%;
					max-height: 80%;
				}
			}
		}
	}
	@include for-tablet-landscape-up() {
		.artwork-grid__item {
			width: 292px;
			height: 292px;
		}
	}
	@include for-desktop-up() {
		&--list {
			grid-column: span 2;
		}
		&--grid {
			grid-column: span 10;
		}
		.artwork-grid__item {
			width: 311.33px;
			height: 311.33px;
			div.artwork-title {
				transition: all .2s ease-out;
				opacity: 0;
				font-family: $titlefont;
				position: absolute;
				background: rgba(255,255,255,.8);
				z-index: 1;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
				&.visible {
					opacity: 1;
				}
			}
		}
	}
	@include for-big-desktop-up() {
		.artwork-grid__item {
			width: 343px;
			height: 343px;
		}		
	}
}

//
// ARTWORK SINGLE VIEWS
//

article.artwork-series-exhibition {
	header {
		// h1 {
		// 	font-family: "freight-display-pro";
		// }
	}
	div.artwork-text {
		grid-column: 1 / -1;
	}
	div.artwork-images {
		grid-column: 1 / -1;

	}
	@include for-desktop-up() {
		div.artwork-text {
			grid-column: span 5;
		}
		div.artwork-images {
			grid-column: 7 / span 6;
			div.grid {
				grid-auto-flow: dense;
				>* {
					grid-column: span 2;
					&.cover {
						grid-column: 2 / span 4;
						img {
							width: 100%;
							padding-bottom: 48px;
						}
					}
				}
			}
		}
	}
	div.artwork-images {
		div.grid {
			@include for-phone-only() {
				grid-template-columns: repeat(1, 1fr);
			}
			>* {
				width: 213px;
				height: 213px;
				display: flex;
				align-items: center;
				justify-content: center;
				img {
					max-width: 90%;
					max-height: 90%;
					&.square {
						max-width: 80%;
						max-height: 80%;
					}
				}
				@include for-phone-only() {
					width: 327px;
					height: 327px;
				}
				@include for-tablet-portrait-up() {
					
				}
				@include for-tablet-landscape-up() {
					width: 292px;
					height: 292px;
				}
				@include for-desktop-up() {
					width: 279px;
					height: 279px;
				}
				@include for-big-desktop-up() {
					width: 319px;
					height: 319px;
				}
			}
		}
	}
	@include for-big-desktop-up() {
		div.artwork-text {
			grid-column: span 4;
		}
		div.artwork-images {
			grid-column: 6 / span 7;
			div.grid {
				grid-template-columns: repeat(3, 1fr);
				>* {
					grid-column: span 1;
				}
			}
		}
	}
}

div.artwork-single {
	a.close-button {
		margin-bottom: 24px;
		// display: flex;
		// justify-content: flex-end;
		
		display: block;
		//float: right;
		div {
			width: 20px;
			height: 20px;
			@include for-tablet-portrait-up() {
				width: 30px;
				height: 30px;
			}
			@include for-big-desktop-up() {
				width: 50px;
				height: 50px;
			}
		}
	}
}

div.artwork-single,
div.artwork-series,
div.exhibition {
	div.gallery {
		&__item {
			position: relative;
		}
		&__image {
			img {
				margin: 0 auto;
			}
		}
	}
}
