// _scss/grid.scss

div#container {
	margin-top: 0;
	//margin-bottom: 48px;
	// @include for-big-desktop-up() {
	// 	margin-top: 60px;
	// }
}

.content-wrapper {
	padding: 0 24px;
	width: 100%;
	@include for-phone-only() {
		width: 327px;
		max-width: 450px;
	}
	@include for-tablet-portrait-up() {
		max-width: 450px;
	}
	@include for-tablet-landscape-up() {
		max-width: 596px;
	}
	@include for-desktop-up() {
		max-width: 1152px;
	}
	@include for-big-desktop-up() {
		max-width: 1692px;
	}
	margin: 0 auto;
	&.header {
		background: white;
	}
}

*.grid {
	word-wrap: break-word;
	display: grid;
	grid-gap: 24px;
	//grid-template-columns: repeat(2, 130px);
	@include for-phone-only() {
		grid-template-columns: repeat(2, 1fr);
	}
	@include for-tablet-portrait-up() {
		grid-template-columns: repeat(auto-fill, minmax(213px, 1fr));
	}
	@include for-tablet-landscape-up() {
		grid-gap: 12px;
		grid-template-columns: repeat(auto-fill, minmax(64px, 1fr));
	}
	@include for-desktop-up() {
		grid-template-columns: repeat(auto-fill, minmax(85px, 1fr));
	}
	@include for-big-desktop-up() {
		grid-template-columns: repeat(auto-fill, minmax(130px, 1fr));
	}
	>* {
		overflow: hidden;  /* NEW */
			min-width: 0;      /* NEW; needed for Firefox */
	}
	&.grid-two {
		grid-template-columns: repeat(2, 1fr);
	}
	&.artwork-grid-overview {
		@include for-phone-only() {
			grid-template-columns: repeat(2, 1fr);
		}
		@include for-tablet-portrait-up() {
			grid-template-columns: repeat(auto-fill, minmax(213px, 1fr));
		}
		@include for-tablet-landscape-up() {
			grid-gap: 12px;
			grid-template-columns: repeat(2, 1fr);
		}
		@include for-desktop-up() {
			grid-template-columns: repeat(3, 1fr);
		}
		@include for-big-desktop-up() {
			grid-template-columns: repeat(4, 1fr);
		}
	}
	&.subgrid {
		@include for-desktop-up() {
				grid-template-columns: repeat(4, 1fr);
			}
	}
}