html {
	height: 100%;
}
body {
	background: white;
	color: black;
	position: relative;
	height: 100%;
	width: 100%;
	&.freeze {
		overflow: hidden !important;
		position: fixed;
		width: 100%;
		main#content {
			// img {
			// 	webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
   //  			filter: grayscale(100%);
			// }
		}
	}
	// &.dev-mode {
	// 	border-top: 10px dashed orange;
	// }
}

.arrow-before {
	&:before {
		content: "";
		background: url('data:image/svg+xml; utf8, <svg width="434px" height="500px" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
			<g style=""><path d="M294.671697,215.182669 L0.000000,215.182669 L0.000000,285.440041 L295.353808,285.440041 L145.289517,449.146539 L191.673025,498.940599 L432.458000,249.970300 L191.673025,1.000000 L145.289517,51.476170 Z" fill="%23000000" stroke="none" />
			</g></svg>');
		background-size: contain;
		margin: 0 .3em 0 0;
		width: .7em;
		height: .7em;
		background-repeat: no-repeat;
		display: inline-block;
		vertical-align: baseline;
		margin-left: -1em;
	}
}

.arrow {
	content: "";
	background-size: contain;
	margin: 0 .3em 0 .6em;
	width: .6em;
	height: .6em;
	background-repeat: no-repeat;
	display: inline-block;
}

main#content {
	box-sizing: border-box;
	&.stiky-nav {
		margin-top: 80px;
	}
}
section {
	.section-header.top {
		@extend .double-bottom-margin;
	}
}
img {
	max-width: 100%;
	display: block;
	&.rounded {
		border-radius: 50%;
	}
	&.bildersets {
		border-radius: 4%;
	}
}
.centered {
	text-align: center;
}
a {
	text-decoration: none;
	color: black;
	&.underline, .links-underlined & {
		text-decoration: underline;
	}
	&.anchor {
		display: none;
	}
	// &:hover {
	// 	color: $primaryColor;
	// }
	&.active, .response & {
		text-decoration: underline;;
	}
	&.more.intern {
		&:after {
			content: "";
			background: url('data:image/svg+xml; utf8, <svg width="434px" height="500px" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
				<g style="">  <path d="M294.671697,215.182669 L0.000000,215.182669 L0.000000,285.440041 L295.353808,285.440041 L145.289517,449.146539 L191.673025,498.940599 L432.458000,249.970300 L191.673025,1.000000 L145.289517,51.476170 Z" fill="%23000000" stroke="none" />
				</g></svg>');
			background-size: contain;
			margin: 0 .3em 0 .6em;
			width: .6em;
			height: .6em;
			background-repeat: no-repeat;
			display: inline-block;
		}
		&:hover {
			&:after {
				content: "";
				background: url('data:image/svg+xml; utf8, <svg width="434px" height="500px" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
					<g style="">  <path d="M294.671697,215.182669 L0.000000,215.182669 L0.000000,285.440041 L295.353808,285.440041 L145.289517,449.146539 L191.673025,498.940599 L432.458000,249.970300 L191.673025,1.000000 L145.289517,51.476170 Z" fill="%2382141e" stroke="none" />
					</g></svg>');
				background-size: contain;
				margin: 0 .3em 0 .6em;
				width: .6em;
				height: .6em;
				background-repeat: no-repeat;
				display: inline-block;
			}
		}
		&.big {
			display: inline-block;
			margin-top: 24px;
			padding: 12px 18px;
			border: 2px solid black;
			&:hover {
				background: black;
				color: white;
				&:after {
				content: "";
				background: url('data:image/svg+xml; utf8, <svg width="434px" height="500px" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
					<g style="">  <path d="M294.671697,215.182669 L0.000000,215.182669 L0.000000,285.440041 L295.353808,285.440041 L145.289517,449.146539 L191.673025,498.940599 L432.458000,249.970300 L191.673025,1.000000 L145.289517,51.476170 Z" fill="%23FFFFFF" stroke="none" />
					</g></svg>');
				background-size: contain;
				margin: 0 .3em 0 .6em;
				width: .6em;
				height: .6em;
				background-repeat: no-repeat;
				display: inline-block;
			}
			}
		}
	}
	&.back.intern {
		&:before {
				content: "";
				background: url('data:image/svg+xml; utf8, <svg width="434px" height="500px" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
					<g style="">  <path d="M137.786316,284.757950 L432.458008,284.757950 L432.458008,214.500580 L137.104187,214.500580 L287.168488,50.794067 L240.784988,1.000000 L0.000000,249.970306 L240.784988,498.940613 L287.168488,448.464443 L137.786316,284.757950 Z" fill="%23000000" stroke="none" />
					</g></svg>');
				background-size: contain;
				margin: 0 .3em 0 0;
				width: .8em;
				height: .8em;
				background-repeat: no-repeat;
				display: inline-block;
			}
	}
	&.boxed {
		border: 2px solid black;
		padding: 16px 24px;
		display: inline-block;
		&:hover {
			color: $fa-red;
			border-color: $fa-red;
		}
	}
	&.grey {
		color: $middle-grey;
	}
	.footer-global & {
		color: $dark-grey;
	}
}
figcaption {
	margin-top: 1rem;
}
ul {
	margin: 0;
	padding: 0;
	li {
		list-style: none;
		.module__body & {
			padding-left: 1.2rem;
			&:before {
				font-family: 'vib-icons';
				content: "\66";
				font-size: .5em;
				vertical-align: middle;
				width: 1.2rem;
				margin-left: -1.2rem;
				display: inline-block;
			}
			&.check {
				&:before {
					content: "\6a";
				}
			}
		}
	}
	&.arrowed {
		li {
			margin-left: 1em;
			&:before {
				content: "";
				background: url('data:image/svg+xml; utf8, <svg width="434px" height="500px" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
					<g style=""><path d="M294.671697,215.182669 L0.000000,215.182669 L0.000000,285.440041 L295.353808,285.440041 L145.289517,449.146539 L191.673025,498.940599 L432.458000,249.970300 L191.673025,1.000000 L145.289517,51.476170 Z" fill="%23000000" stroke="none" />
					</g></svg>');
				background-size: contain;
				margin: 0 .3em 0 0;
				width: .7em;
				height: .7em;
				background-repeat: no-repeat;
				display: inline-block;
				vertical-align: baseline;
				margin-left: -1em;
			}
		}
	}
}
section {
	&.double-bottom-margin {
		margin-bottom: 4em;
	}
}
div.system-note {
	text-align: center;
	&.empty-state {
		margin-top: 10rem;
	}
}
.pagination {

}
.hidden {
	display: none !important;
}
html.touch {
	.no-touch {
		display: none;
	}
}

hr.headerrule {
	border-top: 1px solid black;
	border-bottom: none;
}

.single-top-padding {
	padding-top: 2rem;
}
.single-bottom-padding {
	padding-bottom: 2rem;
}
.double-top-padding {
	padding-top: 2rem;

}
.double-bottom-padding {
	padding-bottom: 2rem;

}

.single-top-margin {
	margin-top: 2rem;
}
.single-bottom-margin {
	margin-bottom: 2rem;
}
.double-top-margin {
	margin-top: 4rem;
}
.double-bottom-margin {
	margin-bottom: 4rem;
}
a.skip {
	color: white;
	position: absolute;
	background: $cyan;
	padding: .5rem 1rem;
	top: -60px;
	&:focus {
		top: 0;
	}
}
div.flash.error, span.error {
	color: red;
}
small {
	display: inline-block;
}
*:focus {
	outline: none;
}

.no-mobile {
	

}
ul.errors {
	color: red;
	margin-top: 6px;
}

.system-note {
	font-family: "Courier New", Courier, monospace;
	&.error {
		background: red;
		color: white;
		padding: .5rem;
		
	}
}
div[role="dialog"] {
	width: 100%;
}
.grey {
	color: $middle-grey;
}
.centered {
	text-align: center;
}
div.page-header, article header {
	margin-bottom: 24px;
}
