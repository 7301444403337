// Layout



// TEMPLATE AREAS

header.page-header, 
div.page-body, 
footer.page-footer 
{
	grid-column: span 2;
	@include for-tablet-landscape-up() {
		grid-column: span 8;
	}
 	@include for-desktop-up() {
		grid-column: 4 / span 6;
	}
	@include for-big-desktop-up() {
		grid-column: 4 / span 6;
	}
}

div.base-grid {



		// HOME
	grid-template-areas: 	"logo-header main-nav"
							"main-content main-content"
							"footer-global footer-global";
	
	@include for-tablet-landscape-up() {
		
		grid-template-areas: 	"logo-header logo-header logo-header main-nav main-nav main-nav main-nav main-nav"
								". main-content main-content main-content main-content main-content main-content ."
								". footer-global footer-global footer-global footer-global footer-global footer-global .";
	}
	@include for-desktop-up() {
		
		grid-template-areas: 	"logo-header logo-header logo-header logo-header logo-header logo-header main-nav main-nav main-nav main-nav main-nav main-nav"
								"main-content main-content main-content main-content main-content main-content main-content main-content main-content main-content main-content main-content"
								". . . footer-global footer-global footer-global footer-global footer-global footer-global . . .";
		}
	@include for-big-desktop-up() {
		
		grid-template-areas: 	". . . header-global header-global header-global main-nav main-nav main-nav . . ."
								"main-content main-content main-content main-content main-content main-content main-content main-content main-content main-content main-content main-content"
								". . . footer-global footer-global footer-global footer-global footer-global footer-global . . .";
	}
}

div.grid *.centered-content {
	grid-column: 1 / -1;
	@include for-tablet-landscape-up() {
		grid-column: 2 / span 6;
	}
	@include for-desktop-up() {
		grid-column: 4 / span 6;
	}
	@include for-big-desktop-up() {
		grid-column: 5 / span 4;
	}
}

div.news {
	div.news-list {
		grid-column: 1 / -1;
		@include for-tablet-landscape-up() {
			grid-column: 2 / span 6;
		}
		@include for-desktop-up() {
			grid-column: 2 / span 2;
		}
		@include for-big-desktop-up() {
			grid-column: 3 / span 2;
		}
	}
}

// div.artwork-series-exhibition {
// 	div.grid {
// 		background: red;
// 		grid-template-areas: 	"area-artwork-text area-artwork-text area-artwork-text area-artwork-text area-artwork-text area-artwork-images area-artwork-images area-artwork-images area-artwork-images area-artwork-images area-artwork-images area-artwork-images";
// 	}
// }

// div.artwork-series-exhibition {

// }

// grid areas

// div.artwork-text {
// 	 grid-area: area-artwork-text;
// }
// div.artwork-images {
// 	 grid-area: area-artwork-images;
// }



// Margins





div.flex {
	@include flex-container();
	&.two-up {
		>* {
			flex: 0 0 auto;
		}
	}
	&.sb {
		justify-content: space-between;
	}
}