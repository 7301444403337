//@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,700,700i');

// Freight Text Pro via Typekit

@import url("https://use.typekit.net/npu3jzr.css");

// FF Yoga Sans
@font-face{
    font-family:"Yoga Sans W01 Regular";
    src:url("../fonts/ff-yoga-sans/7c2758e5-2b3b-490a-968a-00b80a0d790a.eot?#iefix");
    src:url("../fonts/ff-yoga-sans/7c2758e5-2b3b-490a-968a-00b80a0d790a.eot?#iefix") format("eot"),url("../fonts/ff-yoga-sans/c0821026-c1fb-4743-ad70-f78368ddfd5f.woff2") format("woff2"),url("../fonts/ff-yoga-sans/4c9b639b-a8f4-4cf0-9113-adfae2208097.woff") format("woff"),url("../fonts/ff-yoga-sans/d05f2c51-db8c-4ca5-a3d8-5e2b72dbd2f8.ttf") format("truetype");
    font-weight:400;
    font-style:normal
}
