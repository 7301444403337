div.slick-arrow {
	width: 20px;
	position: absolute;
	z-index: 1;
	cursor: pointer;
	&.slick-prev {
	}
	&.slick-next {
		right: 0;
	}
	@include for-phone-only() {
		display: none !important;
	}
	@include for-tablet-portrait-up() {
		top: 35%;
	}
	@include for-tablet-landscape-up() {
		width: 30px;
		top: 38%;
	}
	@include for-desktop-up() {

	}
	@include for-big-desktop-up() {
		top: 41%;
	}
}

@include for-phone-only() {
	div.gallery-carousel {
		// background: red;
	}
}

div.gallery-carousel,
div.gallery-carousel-lightbox {
	div.gallery__image {
		margin: 0 auto;
		display: flex;
		align-items: center;
		justify-content: center;
		@include for-phone-only() {
			max-width: 375px;
			height: 365px;
			img {
				max-height: 100%;
				max-width: 375px;
	  			max-width: 100%;
			}
		}
		@include for-tablet-portrait-up() {
			width: 450px;
			height: 450px;
			img {
				max-height: 380px;
			}
		}
		@include for-tablet-landscape-up() {
			width: 596px;
			height: 596px;
			img {
				max-height: 500px;
			}
		}
		@include for-desktop-up() {
			//width: 1152px;
			height: 400px;
			img {
				max-height: 400px;
			}
		}
		@include for-big-desktop-up() {
			//width: 1692px;
			height: 800px;
			img {
				max-height: 800px;
			}
		}
	}
	div.gallery__artwork-title {
		position: relative;
		div.close-info {
			right: 12px;
			top: 12px;
			left: auto;
			width: 20px;
			height: 20px;
			position: absolute;
			display: none;
			cursor: pointer;
		}
		small.artwork-info {
			display: none;
		}
		small.toggle-info {
			font-style: italic;
			//background: red;
		}
		&.visible {
			small.artwork-info,
			div.close-info {
				display: block;
			}
			small.toggle-info {
				display: none;
			}
		}
		@include for-big-desktop-up() {
			div.close-info,
			small.toggle-info {
				display: none;
			}
			small.artwork-info {
				display: block;
			}
		}
	}
}