// Mixins

@mixin for-phone-only {
  @media (max-width: 599px) { @content; }
}
@mixin for-tablet-portrait-up {
  @media (min-width: 600px) { @content; }
}
@mixin for-tablet-landscape-up {
  @media (min-width: 900px) { @content; }
}
@mixin for-desktop-up {
  @media (min-width: 1200px) { @content; }
}
@mixin for-big-desktop-up {
  @media (min-width: 1820px) { @content; }
}


// Background images + high res
@mixin background-image($image, $image2x, $color: transparent) {
  background: $color url($image) no-repeat;
  background-size: cover;
  background-position: center center; 
  @media only screen and (-webkit-min-device-pixel-ratio: 2),
        only screen and (    min--moz-device-pixel-ratio: 2),
        only screen and (    -o-min-device-pixel-ratio: 2/1),
        only screen and (         min-device-pixel-ratio: 2),
        only screen and (            min-resolution: 192dpi),
        only screen and (             min-resolution: 2dppx) {
          background: $color url($image2x) no-repeat;
          background-size: cover;
          background-position: center center; 
  }
}

@mixin aspect-ratio($width, $height) {
  position: relative;
  &:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: ($height / $width) * 100%;
  }
  > .content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

// Flexbox

@mixin flex-container($direction:row wrap, $justify:flex-start, $align: flex-start) {
	display: flex;
	flex-flow: $direction;
	justify-content: $justify;
	align-items: $align;
}

@mixin flex-item($grow: 0, $shrink: 0, $width: auto) {
	-webkit-flex: $grow $shrink $width;
	-moz-flex: $grow $shrink $width;
	-ms-flex: $grow $shrink $width;
	flex: $grow $shrink $width;
}

@mixin card-heights($bp-small: auto, $bp-medium: $bp-small, $bp-large: $bp-medium, $bp-xlarge: $bp-large, $bp-xxlarge: $bp-xlarge, $bp-huge: $bp-xxlarge, $bp-giga: $bp-huge) {
  height: 130px;
  @media screen and (min-width: $small) {
    height: $bp-small;
  }
  @media screen and (min-width: $medium) {
    height: $bp-medium;
  }
  @media screen and (min-width: $large) {
    height: $bp-large;
  }
  @media screen and (min-width: $xlarge) {
    height: $bp-xlarge;
  }
  @media screen and (min-width: $xxlarge) {
    height: $bp-xxlarge;
  }
  @media screen and (min-width: $huge) {
    height: $bp-huge;
  }
  @media screen and (min-width: $giga) {
    height: $bp-giga;
  }
}

@mixin grid-heights($null: auto, $medium: $null, $large: $medium, $xlarge: $large, $xxlarge: $xlarge, $huge: $xxlarge) {
  height: $null;
  @media screen and (min-width: $medium) {
    height: $medium;
  }
  @media screen and (min-width: $large) {
    height: $large;
  }
  @media screen and (min-width: $xlarge) {
    height: $xlarge;
  }
  @media screen and (min-width: $xxlarge) {
    height: $xxlarge;
  }
  @media screen and (min-width: $huge) {
    height: $huge;
  }
}