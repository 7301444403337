// partials/_home.scss

div#cover-home {
	position: absolute;
	height: 100vh;
	height: calc(var(--vh, 1vh) * 100);
	width: 100%;
	background-color: white;
	top: 0px;
	left: 0px;
	z-index: -1;
	// img {
	// 	width: 100%;
	// }
	transition: all .2s ease-out;
	background-size: cover !important;
	background-repeat: no-repeat !important;
	background-position: center center !important;
	
	//background: url('../img/cover-home/swan-cover-home.jpg') no-repeat center center;
	background-size: cover !important;
	background-position: center center !important;
	@media screen and (orientation: portrait) {
		//background: url('../img/cover-home/swan-cover-home_portrait.jpg') no-repeat center center;
		background-size: cover;
	}
	
}

a.start {
		display: block;
		position: absolute;
		height: 100vh;
		width: 100%;
		top: 0px;
	left: 0px;
	}

div.slideshow.index {
	height:100vh;
	div.slick-list {
		height: 100vh;
	}
	div.slick-track {
		height: 100vh;
	}
	div.slideshow__item {
		height: 100%;
		background-size: cover !important;
	background-repeat: no-repeat !important;
	background-position: center center !important;
	}
	div.slick-slide {
		max-height: 100vh;
		display: flex;
		align-items: center;
		overflow: hidden;
		>div {
			width: 100%;
			height: 100%;
		}
		img {
			min-width: 100%;
			height: auto;
			display: block;
			    
			@media screen and (orientation: landscape) {
				margin-top: -25vh;
			}
			@media screen and (orientation: portrait) {
				min-height: 100%;
				width: auto;
				max-width: none;
				margin-left: 50%;
				transform: translateX(-50%);
			}
		}
	}
	
}