// components/_nav.scss

div.main-nav {
	// text-align: right;
	// display: flex;
	// justify-content: flex-end;
	nav.nav-global {
		display: none;
		@include for-desktop-up() {
			display: block;
		}
		a {
			&:hover {
				text-decoration: underline;
			}
		}
		ul {
			opacity: 0;
			transition: margin 700ms ease-out;
			margin-top: -5em;
			@include for-phone-only() {
				margin-top: 0;
				font-size: 18px;
			}
			@include for-desktop-up() {
				margin-top: 0;
				opacity: 1;
			}
			li {
				display: inline-block;
				@include for-desktop-up() {
					&:not(:last-child) {
						margin-right: 2em;
						margin-top: 0;
					}
				}
			}
		}
		&.visible {
			z-index: 99998;
			display: flex;
			align-items: center;
			background: rgba(255, 255, 255, 0.9);
			position: fixed;
		    left: 0;
		    text-align: center;
		    width: 100vw;
		    height: 100vh;
		    top: 0;
		    ul {
		    	opacity: 1;
		    	margin-top: -3em;
		    	transition: margin 700ms ease-out;
		    	width: 100%;
		    	li {
		    		display: block;
		    		margin-bottom: 1em;
		    	}
		    }
		}
	}
}


// BURGER

.navbar-toggle {
	width: 26px;
	height: 14px;
	cursor: pointer;
	border: none;
	right: 0;
	float: right;
	.icon-bar {
		-webkit-transition: all .2s; /* Safari */
    	transition: all .2s;
		margin: 0 auto;
		height: 1px;
		background: black;
		display: block;
		&:not(:last-of-type) {
			margin-bottom: 4px;
		}
		&:nth-child(2) {
			margin-bottom: 0;
		}
		&:nth-child(3) {
			margin-top: -1px;
		}
	}
	&.open {
		z-index: 99999;
		position:relative;
		.icon-bar {
			background: black;
			&:last-of-type, &:first-of-type {
				opacity: 0;
			}
			&:nth-child(2) {
				-ms-transform: rotate(45deg); /* IE 9 */
			    -webkit-transform: rotate(45deg); /* Chrome, Safari, Opera */
			    transform: rotate(45deg);
			}
			&:nth-child(3) {
				-ms-transform: rotate(-45deg); /* IE 9 */
			    -webkit-transform: rotate(-45deg); /* Chrome, Safari, Opera */
			    transform: rotate(-45deg);
			}
		}
	}
	@include for-desktop-up() {
		display: none;
	}
}

// BREADCRUMB

nav.breadcrumbs {
	margin-bottom: 24px;
	span.arrows svg {
		height: .5em;
		margin: 0 3px;
	}
}
