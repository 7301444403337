.input-styles {
	&:not(.couponCode) {
		width: 100%;
	}
	width: 100%;
	border: 1px solid $middle-grey;
	border-radius: 0;
	//box-shadow: inset 1px 1px 2px lighten($dark-grey, 40%);
	//padding: 0 1rem;
	box-sizing: border-box;
}

form {
	margin: .5em 0;
	input {
		border-radius: 0;
		border: 1px solid black;
		padding: 12px;
		&[type="search"] {
			-webkit-appearance: none;
		}
		&[type="text"], &[type="email"], &[type="password"], &[type="number"] {
			@extend .input-styles;
			height: 48px;
			border-color: $light-grey;
			&:focus {
				border-color: black;
				color: black;
			}
		}

		&[type="text"] {
			footer.footer-global & {
				margin-right: 1rem;
				width: 400px;
			}
			&.couponCode {
				text-transform: uppercase;
			}
		}
		&[type="number"] {
			width: auto;
		}
		&[type="checkbox"] {
			margin-right: .5rem;
		}
		&[type="submit"] {
			//background: darken(white, 3%);
			margin-top: 12px;
			&.button {
				//height: 48px;
			}
		}
		&.coupon {
			margin-top: .5rem;
		}
		&.outline {
			border: 2px solid black;
		}
		&.coupon-highlight {
			border: 1px solid $fa-red;
			color: $fa-red;
			font-weight: 700;
		}
		&.mce_inline_error {
			border-color: black !important;
		}
	}
	select {
		width: auto;
		option {
			// font-size: 10px;
		}
	}
	label {
		margin-top: 24px;
	}
	&.outside {
		label {
			margin-bottom: 6px;
			text-transform: uppercase;
			letter-spacing: .05em;
			color: $middle-grey;
			//font-weight: 900;
		}
	}
}
span.flash.error {
	margin-bottom: 6px;
	display: inline-block;
}



div.checkout-button {
	text-align: right;
}

#mc_embed_signup div.mce_inline_error {
	background: black !important;
	font-style: italic;
}
.response {
	margin-top: 24px;
}