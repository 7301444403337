// partials/_artist.scss

div.image,
div.about {
	grid-column: 1 / -1;
	@include for-tablet-landscape-up() {
		grid-column: 2 /span 6;
	}
}

@include for-desktop-up() {
	div.about-section {
		margin-bottom: 6em;
	}
	div.image {
		img {
			//max-width: 80%;
		}
		&.image-01 {
			overflow: visible;
			grid-column: 1 / span 3;
		}
		&.image-02 {
			grid-column: 10 / span 3;
			margin-top: 240px;
			img {
				float: right;
			}
		}
	}
	div.about {
		grid-column: 4 / span 6;
		margin-top: 120px;
	}

}

@include for-big-desktop-up() {
	div.about-section {
		margin-bottom: 2em;
	}
	div.image {
		img {
			max-width: 80%;
		}
		&.image-01 {
			grid-column: 1 / span 4;
		}
		&.image-02 {
			grid-column: 9 / span 4;
			margin-top: 240px;
		}
	}
	div.about {
		grid-column: 5 / span 4;
		margin-top: 120px;
	}

}

div.education-section {
	margin-top: 24px;
	div.biography {
		p.event-type {
			padding-bottom: 12px;
		}
	}
}


table.biography {
	td.year {
		width: 100px;
		@include for-desktop-up() {
			width: 150px;
		}
	}
	td.event {
		padding-bottom: 12px;
	}
	margin-bottom: 48px;
}
div.simple-text {
	margin-bottom: 48px;
}