@charset "UTF-8";

// @font-face {
//   font-family: "axel";
//   src:url("../fonts/axel/Axel-Regular.eot");
//   src:url("../fonts/axel/Axel-Regular.eot?#iefix") format("embedded-opentype"),
//     url("../fonts/axel/Axel-Regular.woff") format("woff"),
//     url("../fonts/axel/Axel-Regular.ttf") format("truetype"),
//     url("../fonts/axel/Axel-Regular.svg#winter") format("svg");
//   font-weight: 400;
//   font-style: normal;
// }

// @font-face {
//   font-family: "axel";
//   src:url("../fonts/axel-bold/Axel-Bold.eot");
//   src:url("../fonts/axel-bold/Axel-Bold.eot?#iefix") format("embedded-opentype"),
//     url("../fonts/axel-bold/Axel-Bold.woff") format("woff"),
//     url("../fonts/axel-bold/Axel-Bold.ttf") format("truetype"),
//     url("../fonts/axel-bold/Axel-Bold.svg#winter") format("svg");
//   font-weight: 700;
//   font-style: normal;
// }


// Typography

// @import url("https://use.typekit.net/tuv0fwd.css");

body {
	font-family: $basefont;
	// -moz-font-feature-settings: "salt";
	// -webkit-font-feature-settings: "salt";
	// font-feature-settings: "salt";
	-moz-font-feature-settings: "locl";
	-webkit-font-feature-settings: "locl";
	font-feature-settings: "locl";
	@media
		only screen and (-webkit-max-device-pixel-ratio: 1) { 

		 

		}
	@media
		only screen and (-webkit-max-device-pixel-ratio: 1)      and (min-width: 320px),
		only screen and (   max--moz-device-pixel-ratio: 1)      and (min-width: 320px),
		only screen and (     -o-max-device-pixel-ratio: 1/1)    and (min-width: 320px),
		only screen and (        max-device-pixel-ratio: 1)      and (min-width: 320px),
		only screen and (                max-resolution: 96dpi) and (min-width: 320px),
		only screen and (                max-resolution: 1dppx)  and (min-width: 320px) { 

		  -webkit-font-smoothing: antialiased;

	}
	font-weight: 400;
	font-size: 18px;
	@include for-desktop-up() {
		font-size: 20px;
	}
	@include for-big-desktop-up() {
		font-size: 24px;
	}
}

h1, h2, h3, p, ul li {
	margin: 0;
}

// article.post header h1, .article-body h2, b, strong {
// 	font-weight: 900;
// }

h1 {
	font-weight: 400;
	font-family: $titlefont;
	-webkit-font-smoothing: antialiased;
}

h2 {
	font-weight: 400;
	&.event-type {
		font-family: $titlefont;
		margin-bottom: 1em;
		font-size: 18px;
	}
}
div.navigation.main-nav li {
	font-size: 18px;
}

p, ul {
	letter-spacing: .01em;
}

// FONT SIZES

h1,
p.site-name
{
	font-weight: 300;
	font-size: 28px;
	line-height: 1.2em;
}

p, 
h2, 
h3, 
blockquote, 
ul, 
ol, 
table,
#mce-success-response,
.response
{
	font-size: 16px;
	line-height: 1.45em;
	.page-body &{
		margin-bottom: 24px;
	}
}
figcaption, 
small, 
.small-font, 
.small-font *, 
.button, 
footer.footer-global ul,
table.cart thead,
form label,
div.radio, 
div.radio ul,
input,
.mce_inline_error {
	font-size: 14px;
	-moz-font-feature-settings: "salt";
	-webkit-font-feature-settings: "salt";
	font-feature-settings: "salt";
	letter-spacing: .02em;
}
blockquote {
	font-style: italic;
}

@include for-tablet-landscape-up() {

}

@include for-desktop-up() {
	h1
	{
		font-size: 56px;
		line-height: 1em;
	}
	h2 {
		&.event-type {
			font-size: 20px;
		}
	}
	div.navigation.main-nav li,
	a.artwork-grid__item .artwork-title p {
		font-size: 20px;
	}
	header.page-header h1 {
		font-weight: 400;
		line-height: 1.4em;
	}
	p, 
	h2, 
	h3, 
	blockquote, 
	ul, 
	ol, 
	table, 
	form select,
	#mce-success-response,
	.response{
		font-size: 16px;
		line-height: 1.5em;
	}
	figcaption, 
	small, 
	.small-font, 
	.small-font *, 
	.button, 
	footer.footer-global ul,
	table.cart thead,
	form label,
	div.radio, 
	div.radio ul,
	input {
		font-size: 14px;
	}
}

@include for-big-desktop-up() {
	h1
	{
		font-size: 62px;
		line-height: 1em;
	}
	h2 {
		&.event-type {
			font-size: 20px;
		}
	}
	p, 
	h2, 
	h3, 
	blockquote, 
	ul, 
	ol, 
	table,
	#mce-success-response,
	.response {	
		font-size: 18px;
		line-height: 1.5em;
	}
	h2 {
		font-size: 20px;
	}
	figcaption, 
	small, 
	.small-font, 
	.small-font *, 
	.button, 
	footer.footer-global ul,
	table.cart thead,
	form label,
	div.radio, 
	div.radio ul,
	input {
		font-size: 14px;
	}
}

// MISC

th, div.additional-content h2.lead {
	font-weight: 400;
}

article.article-single .intro, blockquote {
	//font-style: italic;
}
small strong {
	-webkit-font-smoothing: antialiased;
}
.uppercase {
	text-transform: uppercase;
}
.sc {
	font-variant: small-caps;
}
.ln {
	font-variant-numeric: lining-nums;
}
em, i {
	-moz-font-feature-settings: "salt";
	-webkit-font-feature-settings: "salt";
	font-feature-settings: "salt";
}
.bold {
	font-weight: 900;
}

.no-margin {
	* {
		margin-bottom: 0 !important;
	}
}
div.order-confirmation,
div.order-my-orders {
	table {
		font-family: 'axel';
		font-size: .8em;
	}
}
div.entry-body {
	p {
		margin-bottom: 12px;
	}
}
