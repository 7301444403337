// components/_buttons.scss


.button {
	background: white;

}
input[type="submit"] {
	&.button {
		background: white;
		&.small {
			padding: 6px;
			display: inline-block;
		}
	}
	&.add-to-cart {
		//margin: 48px 0 12px 0;
		background: $yellow;
		padding: 36px 48px;
		font-weight: 700;
		border: none;
		text-transform: uppercase;
		width: 100%;
		&:hover {
			background: lighten($yellow, 10%);
		}
		&:focus:active {
			background: darken($yellow, 10%);
		}
	}
	&.clear-cart {
		margin-bottom: 24px;
	}
}

button.button, a.button, input[type="submit"] {
	display: inline-block;
	padding: 12px;
	//font-weight: 900;
	&.button-primary {
		border: 2px solid black;
	}
	&.full {
		display: block;
	}
	&.button-primary {
		border: 2px solid black;
	}
}