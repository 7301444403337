// components/_lightbox.scss

div.lightbox {

	background: white;
	position: fixed;
	top:0;
	left:0;
	width: 0;
	height: 0;
	margin-top: -100vh;
	height: calc(var(--vh, 1vh) * 100);
	z-index: 99999999;
	display: flex;
	align-items: center;
	justify-content: center;
	&.visible {
		width: 100vw;
		height: calc(var(--vh, 1vh) * 100);
		margin-top: 0;
	}
	div.gallery-carousel-lightbox {
		display: flex;
		align-items: center;
		div.slick-arrow {
			//background: red;
			top: auto;
			&.slick-prev {
				left: 24px;
			}
			&.slick-next {
				right: 24px;
			}
		}
	}
	div.gallery-wrapper {
		width: 100vw;
		height: 100vh;
		//background: red;
	}
	div.gallery {
		margin: auto 0;
		// background: green;
		div.gallery__item {
			//background: blue;
			height: 100vh;
			display: flex;
			align-items: center;
			position: relative;
			div.gallery__image {
				height: 100vh;
				width: 100vw;
				img {
					max-height: calc(100vh - 48px);
					max-width: calc(100vw - 48px);
				}
				@include for-tablet-portrait-up() {
					img {
						max-height: calc(100vh - 48px);
						max-width: calc(100vw - 160px);
					}
				}
			}
			div.gallery__artwork-title {
				cursor:pointer;
				@media screen and (orientation:portrait) {
					padding-right: 48px;
					bottom: 52px !important;
				}
				@at-root {
					html.ua-safari.ua-mobile-iphone div.gallery__artwork-title,
					html.ua-mobile div.gallery__artwork-title  {
						@media screen and (orientation:portrait) {
							bottom: 52px !important;
						}
					}
				}
				background: white;
				position: absolute;
				bottom: 12px;
				left:12px;
				padding: 12px;
				small.toggle-info {
					cursor: pointer;
					width: 30px;
					height: 18px;
					svg {
						width: 30px;
						cursor: pointer;
					}
				}
			}
		}
	}
	div.close,
	div.close-back {
		position: absolute;
		left: 24px;
		top: 24px;
		width: 20px;
		height: 20px;
		cursor: pointer;
		z-index: 1;
		@include for-tablet-landscape-up() {
			width: 30px;
			height: 30px;
		}
		// svg {
		// 	width: 20px;
		// 	height: 20px;
		// 	@include for-tablet-landscape-up() {
		// 		max-width: 30px;
		// 		max-height: 30px;
		// 	}
		// }
	}
}